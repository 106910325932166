import { ReactNode } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

type GoogleRecaptchaProviderProps = {
  children: ReactNode
}

export const GoogleRecaptchaProvider = ({
  children,
}: GoogleRecaptchaProviderProps) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}>
      {children}
    </GoogleReCaptchaProvider>
  )
}
