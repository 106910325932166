import { Session } from 'next-auth'
import { GetAddressSuggestionsResponse } from 'shared-types'
import { request } from './request'

export class ExperianClient {
  getAddressSuggestions = async (
    params: string,
    session: Session
  ): Promise<GetAddressSuggestionsResponse> => {
    const response = await request<GetAddressSuggestionsResponse>(
      {
        url: `bff/experian/address?${params}`,
        method: 'POST',
      },
      session
    )
    if (response.getErrorSafe()) {
      return null
    }

    return response.getValueSafe()
  }
}
